@media screen {

  .Toggle {
    background: var(--colorlink);
    border-radius: 5rem;
    border-width: 0;
    bottom: 2rem;
    color: white;
    display: block;
    font-size: 1.15rem;
    font-weight: bold;
    height: 5rem;
    outline: none;
    overflow: hidden;
    position: fixed;
    right: 2rem;
    text-align: center;
    top: auto;
    user-select: none;
    width: 5rem;
    z-index: 5;
    -webkit-appearance: none;
  }

  @media (min-width: 48em), (orientation: landscape) {
    .Toggle {
      bottom: 3rem;
      right: 3rem;
    }
  }

  @media (min-width: 78em) {
    .Toggle {
      bottom: 4rem;
      right: 4rem;
    }
  }

  .Toggle:active {
    background: #003064;
    box-shadow: 0 0 10px rgb(0, 151, 229);
  }

  .Toggle.Toggle--close {
    background: #13b108;
    font-size: 1.75rem;
  }

  .Toggle.Toggle--close:active {
    background: #1ac10f;
    box-shadow: 0 0 7px #1ac10f;
  }
}

@media print {
  .Toggle {
    display: none;
  }
}
