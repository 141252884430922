:root {
  --colorlink: #1d6ad0;
  --cellpadding: .8em 1.3em;
  --maxwidth: 56rem;
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  /* outline: 10px dashed #3fd0d0; */
  /* overflow-y: scroll; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen {
  html,
  body {
    background-color: #eee;
    color: #2a2a2a;
  }
  @media (max-width: 48em) {
    html,
    body {
      background-color: white;
    }
  }
}

#root {
  min-height: 100vh;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

blockquote {
  color: #6f6f6f;
}

blockquote, h1, h2, h3, h4, h5, h6, hr, p {
  max-width: var(--maxwidth);
}

h1 {
  font-size: 2em;
}

@media (max-resolution: 150dpi) {
  h3 {
    font-weight: 500;
  }
}

p {
  line-height: calc(21 / 16);
}

code {
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
}

p code {
  background: #eee;
  display: inline-block;
  padding: 0 .4em;
}

a {
  color: var(--colorlink);
}

th {
  background-color: #fcfcfc;
  font-weight: 600;
}

blockquote {
  border-left: .2em solid #d4d4d4;
  margin: 0;
  padding: .2em .2em .2em 1em;
}

hr,
pre,
table {
  margin: 2.67em 0;
}

table {
  background: white;
  border-collapse: collapse;
}

td,
th {
  border: 1px solid #ccc;
  padding: var(--cellpadding);
}

td {
  text-align: center;
}

th {
  text-align: center;
}

hr {
  border-width: 0;
  border-bottom: 1px solid #d6d6d6;
}

[contenteditable]:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

@media print {

  body {
    font-family: -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  }

  a::after {
    content: " (" attr(href) ")";
  }
  
  h1 {
    page-break-before: always;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }

  dl,
  ol,
  ul {
    page-break-before: avoid;
  }

} /* end @media print*/
