.ace_editor {
  border-left: var(--edit-padding) solid rgb(20, 20, 20);
  width: 100% !important;
}

@media print {
  .Editor,
  .ace_mobile-menu {
    display: none;
  }
}
