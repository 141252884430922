:root {
  --editor-width: 100vw;
  --editor-height: 100vh;
  --edit-padding: 12px;
}
@media screen {

  @media (min-width: 48em), (orientation: landscape) {
    :root {
      --editor-width: 50vw;
    }
  }
  @media (min-width: 48em) {
    :root {
      --edit-padding: 2rem;
    }
  }
  @media (max-width: 48em) {
    :root {
      --editor-width: 100vw;
    }
  }
  .Editor {
    display: none;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  .Editor.Editor--open {
    display: block;
  }
  .Result {
    background-color: white;
    padding: 0 12px 100px 12px;
    position: relative;
    z-index: 3;
  }
  .Result article {
    overflow: auto;
  }
  .Result section {
    width: 100%;
  }
  @media (max-width: 48em) {
    .App--edit .Result {
      display: none;
    }
  }
  @media (min-width: 48em), (orientation: landscape) {
    .App {
      align-items: stretch;
      align-content: stretch;
      bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      left: 0;
      overflow: hidden;
      right: 0;
      top: 0;
    }
    .App--edit {
      background-color: white;
      position: fixed;
    }
    .Editor.Editor--open {
      flex: 0 0 var(--editor-width);
      padding: 0;
    }
    .Result {
      width: 100%;
    }
    .App--edit .Result {
      overflow: scroll;
      padding: 0 var(--edit-padding);
      /* perspective: 800px; */
    }
    .App--edit .Result article {
      /* max-height: 100vh; */
      /* margin: calc(0.23086819112 * 8rem) 0; */
      min-height: 100vh;
      /* transform: rotateY(7deg); */
    }
    .Result section {
      background-color: white;
    }
    .App--view .Result section {
      padding: 1rem 3rem;
    }
  }
  @media (min-width: 48em) {
    .App--view .Result {
      background-color: #eee;
      /* padding: 0 1rem; */
      padding: 0 5rem;
    }
    .App--view .Result section {
      min-height: 100vh;
      padding: 1cm 3cm;
    }
  }
  @media (min-width: 64em) {
    .App--view .Result {
      padding: 0 11rem;
    }
  }
}

.no-scroll {
  overflow: hidden;
}